<template>
  <div class="wrapper">
    <div class="container">
      <div class="popular">
        <div class="games">
          <p>{{ $t("hotGames") }}</p>
          <div class="popular-games">
            <div class="popular-games" v-if="hotGamesLoading">
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
              <div class="skeleton_item game-icon"></div>
            </div>
            <div
              v-for="(game, index) in homePageGame"
              v-bind:key="getKey(index)"
              class="crash-game fly-game"
              v-else
            >
              <div class="game-icon" @click="openSlideUp(game)">
                <img
                  loading="lazy"
                  v-bind:src="game.image"
                  :alt="game.game_name"
                  data-toggle="modal"
                  v-bind:data-target="'#' + game.game_id"
                />
                <!-- <span>{{ game.game_name }}</span> -->
                <span>{{ truncateFirstWord(game.game_name) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChopbetSlideUp
        :isOpen="isSlideUpOpen"
        @closeSlideup="closeSlideUp"
        :fullHeight="false"
      >
        <div :class="isLoaded ? 'tick' : 'chopslide'">
          <div class="chopslide-heading" v-show="isLoading || isLoaded">
            <div class="choplogo">
              <ChopbetLogo />
            </div>
            <div class="close-btn">
              <div class="control-button" v-if="isLoaded">
                <router-link to="/deposit" class="special-font change">
                  <ChopbetButton :casino="true">
                    {{ $t("deposit") }}</ChopbetButton
                  >
                </router-link>
              </div>
              <div class="close" @click="closeSlideUp">
                <CloseIcon />
              </div>
            </div>
          </div>

          <iframe
            allow="fullscreen"
            v-show="isLoaded"
            class="casino-iframe"
            v-bind:src="launchURL"
            allowfullscreen
            webkitallowfullscreen
          ></iframe>

          <div class="preview-game" v-show="!isLoading && !isLoaded">
            <div class="modal-img">
              <img
                v-if="previewGame"
                loading="lazy"
                v-bind:src="previewGame.image"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + previewGame.game_id"
              />
              <div class="preview-name">
                <p>{{ previewGame.game_name }}</p>
              </div>
            </div>
            <div class="control-button">
              <ChopbetButton
                @click="getLaunchUrl(previewGame)"
                :loading="isLoading"
              >
                {{ $t("playGames") }}</ChopbetButton
              >
            </div>
          </div>
          <div class="slide-loading" v-show="isLoading">
            <p>{{ $t("loading") }}...</p>
            <div class="progress-track">
              <div class="level" :style="{ width: 70 + '%' }"></div>
            </div>
          </div>
        </div>
      </ChopbetSlideUp>
    </div>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
// const Slider = () => import("../../Slider.vue");
import providerServiceMap from "@/services/providerServiceMap";
import getProviderPayload from "@/utils/getProviderPayload";
import casino from "@/services/casino";
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import ChopbetButton from "../ui/ChopbetButton.vue";
import CloseIcon from "../icons/CloseIcon.vue";
import ChopbetLogo from "../icons/ChopbetLogo.vue";

export default {
  name: "PopularGames",
  data() {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      previewGame: {},
      isLoaded: false,
      isLoading: false,
      isSlideUpOpen: false,
      myProfile: this.getProfile(),
      hotGamesLoading: true,
      aviatorGame: [
        {
          providerID: 3,
          game_id: "1",
          game_name: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
        {
          providerID: 1,
          game_id: "aviator",
          game_name: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          game_id: 4,
          provider_id: 3,
          game_name: "Taxi Ride",
          image: "/img/home/taxi_ride.png",
        },
      ],
      homePageGame: [],
      homePageGameStatic: [
        {
          provider_id: 1,
          game_id: "aviator",
          game_name: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          game_id: 5,
          provider_id: 3,
          game_name: "Monkey Bizniz",
          image: "/img/home/Monkey.webp",
        },
        {
          game_id: "plinko",
          provider_id: 1,
          game_name: "Plinko",
          image: "/img/home/plinko_spribe.png",
        },
        {
          game_id: 4,
          provider_id: 3,
          game_name: "Taxi Ride",
          image: "/img/home/taxi_ride.png",
        },
        {
          game_id: "dice",
          provider_id: 1,
          game_name: "Dice",
          image: "/img/home/dice_spribe.png",
        },
        {
          game_id: 1,
          provider_id: 3,
          game_name: "Comet",
          image: "/img/home/comet_crash.png",
        },
      ],
      casinoCategoryActive: {
        id: 1,
        name: "Crash",
      },
    };
  },
  components: {
    ChopbetLogo,
    ChopbetSlideUp,
    ChopbetButton,
    CloseIcon,
  },
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
    truncateFirstWord(gameName) {
      const firstWord = gameName.split(" ")[0];
      return firstWord.length > 7
        ? firstWord.substring(0, 7) + "..."
        : firstWord;
    },
    closeModal() {
      const modals = document.getElementsByClassName("modal-window");
      for (let i = 0; i < modals.length; i++) {
        if (!modals[i].classList.contains("d-none")) {
          modals[i].classList.add("d-none");
          break;
        }
      }
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      // console.log(this.$store.state.sport_id);
      this.$router.push({ name: "sport", params: {} });
    },
    openSlideUp(game) {
      this.previewGame = game;
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isLoaded = false;
      this.isSlideUpOpen = false;
    },
    getLaunchUrl(data) {
      if (!this.profile) {
        this.setError(this.$t("pleaseLoginProceed"));
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      this.isLoading = true;

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var game_name = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = getProviderPayload(
        providerId,
        accountID,
        gameId,
        game_name,
        device_type
      );

      if (!payload) {
        this.isLoading = false;
        return;
      }
      return this.launchGame(payload);
    },
    async getCasinoGames() {
      const vm = this;
      try {
        const category_id =
          process.env.VUE_APP_ENVIRONMENT === "development" ? "1" : "2";
        var path = `/games?page=1&per_page=10&category_id=${category_id}`;
        const res = await casino.get(path);
        const hotGames = res.data.data;
        if (hotGames.length > 5) {
          vm.homePageGame = hotGames;
        } else {
          vm.homePageGame = vm.homePageGameStatic;
        }
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        vm.hotGamesLoading = false;
      }
    },
    launchGame: function (payload) {
      var vm = this;
      var providerId = payload.providerId;
      var service = providerServiceMap[providerId];
      if (!service) {
        this.isLoading = false;
        return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          // console.log("LaunchURL", vm.launchURL);
          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }
          // console.log("LaunchUrl", vm.launchURL);
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          this.isLoading = false;
          return vm.launchURL;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.closeSlideUp();
          this.setError(`Invalid game id`);
        });
    },
  },
  computed: {
    profile: function () {
      return this.myProfile;
    },
  },
  mounted() {
    this.getCasinoGames();
  },
};
</script>
